<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/EnergyvsPowerwHBM-min.png"
              width="876"
              height="523"
              alt=""
              title="EnergyvsPowerwHBM"
              class="img-responsive wp-image-12078"
          /></span>
          <div
            class="fusion-title title fusion-title-1 fusion-title-text fusion-title-size-three"
            style="margin-top:20px;margin-bottom:5px;"
          >
            <TitleComponent
              style="padding-bottom:0rem;"
              title="Electrical Resistivity Measurement"
            />
          </div>
          <Paragraph>
            <p>
              In addition to the ESD risk evaluation, SMS can perform detailed
              surveys on electrical continuity (grounding and bonding) of
              process facilities and equipment. SMS has the capability to
              measure the resistivity of conductive floors, tabletops, and mats
              or runners and can perform the testing in accordance with DOD
              4145.26-M C6.4.7.5.5 Test Procedure. For DOD product
              manufacturers, conductive floors and table tops must be tested at
              least annually and all test records kept for a minimum of 5 years.
              SMS will provide a written report with an assessment of the
              current electrostatic controls based on the data obtained through
              the survey. As required, recommendations for corrective actions or
              improvements of the electrostatic control strategies will be
              provided.
            </p>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent
          style="padding-bottom:1rem;"
          title="ESD Risk Evaluation"
          size="large"
        />
        <Paragraph
          ><p>
            SMS has developed the capability to quantitatively assess the risk
            of electrostatic discharge (ESD) from persons working with static
            sensitive materials such as explosives, flammable vapors and gases,
            Electro-Explosive Devices (EEDs), and electronic components or
            articles. The approach for this quantitative study includes:
          </p>
          <ul>
            <li>
              Measurement and documentation of the maximum electric potential
              found on operators under their various operating conditions
            </li>
            <li>
              Quantification and documentation of ESD discharge current traces
              under these various conditions
            </li>
          </ul>
          <p>
            With this information, SMS compares these actual measured energies
            against the ESD sensitivity of the material receptor(s) (e.g.
            explosives, flammables, articles, etc…). From this comparison, an
            assessment of risk can be determined.
          </p>
          <p>
            Electrostatic sensitive articles and substances can be initiated
            from human ESD through a contact or air discharge. As the charged
            individual approaches the article or substance, the difference in
            potential can cause the air to become ionized and thereby reduce the
            resistance sufficiently to favor an air discharge event over a
            contact discharge. A contact discharge is where the charge
            accumulated on the individual isn’t transferred until contact. Air
            discharges are more likely at higher electric potentials and result
            in a smaller energy transfer than a contact discharge due to the
            energy diffused in the spark gap. Most risk evaluations assume a
            contact discharge always occurs including the Human Body Model.
          </p>
          <p>
            For example, shown below is a plot of the energy and power required
            for the ignition of multiple similar devices (shown as points at the
            right of the plot). On the left of the plot is shown the energy and
            power measured for human electrostatic discharge events. The line
            with black diamonds is for the Human Body Model (which assumes
            discharge of the electrostatic charge upon contact). The maroon
            diamonds are the energy and power of actual human discharges
            measured at a production facility at various charge levels. All of
            the measured discharge events were sparks across an air gap to the
            charge collection device. The blue triangles indicate the calculated
            power and energy of the human discharge events if the measured spark
            discharges were actually contact discharges.
          </p>
          <p>
            As shown in the figure an individual would have to be charged
            excessively to impart sufficient energy to initiate a device. The
            likelihood of a human ESD event resulting in initiation of the
            example devices is extremely unlikely as there is a significant
            difference between the required energy to ignite the device and the
            energy of a human ESD event.
          </p>
          <p>
            The process for identifying the risk of initiation includes
            comparing the energy required for initiation to the energy of a
            human ESD event under the conditions at the processing facility. The
            following steps are completed to quantify the ESD risk:
          </p>
          <ul>
            <li>
              Quantify the maximum electric potential of the operators in the
              scenario of interest using an electrostatic field meter
            </li>
            <li>
              Actively charge and discharge a person under similar circumstances
              and record the discharge current
            </li>
            <li>Quantify the energy and power of the discharge events</li>
            <li>
              Compare the energies of the human ESD discharge event to the
              required initiation energy at a given power
            </li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "ESD Risk Evaluation",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can assess electrostatic discharge (ESD) using quantitative means."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
